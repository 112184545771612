import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Le site internet de l'entreprise est un site de vente en ligne.
			</title>
			<meta name={"description"} content={"Plongez dans l'élégance - Découvrez notre gamme de services d'entretien automobile sereins\n"} />
			<meta property={"og:title"} content={"Le site internet de l'entreprise est un site de vente en ligne."} />
			<meta property={"og:description"} content={"Plongez dans l'élégance - Découvrez notre gamme de services d'entretien automobile sereins\n"} />
			<meta property={"og:image"} content={"https://marseilleharbor.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://marseilleharbor.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://marseilleharbor.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://marseilleharbor.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://marseilleharbor.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://marseilleharbor.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://marseilleharbor.com/images/icon.png"} />
		</Helmet>
		<Components.Heaeder />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Points forts du service
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					La station de lavage Bubble Bliss propose une série de services conçus pour apporter à votre véhicule un soin exceptionnel pendant que vous profitez d'un moment de sérénité. Du lavage méticuleux à l'esthétique rajeunissante, nous sommes votre havre de paix pour l'entretien de votre voiture.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Contacts
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://marseilleharbor.com/images/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Nos services
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Notre option de service complet offre un nettoyage en profondeur de l'extérieur et de l'intérieur, y compris l'aspiration, le nettoyage des vitres et le dépoussiérage du tableau de bord, pour un rafraîchissement général rajeunissant.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Options de lavage
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Lavage de base : Notre lavage de base utilise des brosses en mousse douce et des shampooings respectueux de l'environnement pour éliminer en douceur la saleté, garantissant un brillant impeccable sans endommager la finition de votre voiture.
							<br />
							<br />
							- Lavage de luxe serein : Améliorez la propreté de votre voiture grâce à notre lavage de luxe, qui comprend le nettoyage du châssis et le lustrage des roues, pour donner à votre véhicule une apparence complète et brillante.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Services d'entretien
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Un service d'esthétique intérieure calme et propre : Plongez au cœur de la propreté avec notre service d'esthétique intérieure, qui se concentre sur les tissus d'ameublement, les tapis et tous les coins et recoins, pour redonner à l'intérieur de votre voiture un état impeccable.
							<br />
							<br />
							- Un service d'esthétique extérieure serein : Protégez et améliorez l'extérieur de votre voiture grâce à notre service d'esthétique, qui comprend le cirage et le polissage, pour un éclat durable et une protection contre les éléments.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Traitements spécialisés
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Cire écologique : Notre cire écologique fournit non seulement une couche protectrice, mais donne également à votre voiture un éclat radieux et durable, reflétant ainsi notre engagement en faveur de l'environnement.
							<br />
							<br />
							- Élimination des odeurs : Faites l'expérience d'une fraîcheur sans précédent grâce à notre service d'élimination des odeurs, laissant votre voiture avec un arôme agréable et accueillant.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="normal 600 24px/1.5 --fontFamily-sans"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Confort supplémentaire
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							- Espace d'attente relaxant : Pendant que nous nous occupons de votre voiture, détendez-vous dans notre salle d'attente sereine, conçue pour la relaxation avec une musique apaisante et des sièges confortables.
							<br />
							<br />
							- Assurance d'un service rapide : Conscients de la valeur de votre temps, nous vous garantissons un service rapide mais complet, vous permettant de profiter d'une voiture impeccable sans attendre.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://marseilleharbor.com/images/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Faites l'expérience de la différence
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Pour en savoir plus sur nos services ou pour planifier votre prochaine visite, n'hésitez pas à nous contacter. Notre équipe se consacre à vous offrir, à vous et à votre véhicule, une expérience de soins automobiles tranquille et de qualité supérieure.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});